import { deleteDistributorEmailTemplate, fetchDistributorEmailTemplates } from '@/api/site/site'
import { useNotifyErrors, useNotifySuccess, useTableOptions } from '@/composables'
import { ref, watch } from '@vue/composition-api'
import { debounce } from 'lodash'

const cached = []

export default templateType => {
  const loading = ref(false)

  const tableColumns = [
    {
      text: 'ID',
      value: 'id',
    },
    {
      text: 'Template Type',
      value: 'type',
    },
    {
      text: 'Distributor',
      value: 'distributor.nicename',
    },
    {
      text: 'ACTIONS',
      value: 'actions',
      align: 'center',
      sortable: false,
    },
  ]

  const monthlySummaryTableColumns = [
    {
      text: 'ID',
      value: 'id',
    },
    {
      text: 'Template Type',
      value: 'type',
    },
    {
      text: 'Distributor',
      value: 'distributor.nicename',
    },
    {
      text: 'Active',
      value: 'active',
    },
    {
      text: 'ACTIONS',
      value: 'actions',
      align: 'center',
      sortable: false,
    },
  ]

  const tableItems = ref([])
  const tableTotal = ref(0)

  const options = ref(
    cached[0] || {
      sortBy: ['id'],
      sortDesc: [true],
      itemsPerPage: 15,
    },
  )

  // Filter
  const filters = ref({
    sub_type: null,
    type: templateType,
    distributor_id: null,
  })

  const loadData = async () => {
    loading.value = true

    try {
      console.log(filters.value)
      const { data } = await fetchDistributorEmailTemplates(useTableOptions(options.value, parseFilters()))

      if (data.status === 'success') {
        tableItems.value = data.data.records
        tableTotal.value = data.data.pagination.total
      }
    } catch (error) {
      console.log(error)
    } finally {
      loading.value = false
    }
  }

  const parseFilters = () =>
    Object.keys(filters.value)
      .filter(key => filters.value[key])
      .reduce(
        (a, key) => ({
          ...a,
          [key]: filters.value[key],
        }),
        {},
      )

  const deleteEmailTemplate = obj =>
    deleteDistributorEmailTemplate(obj.id)
      .then(() => {
        useNotifySuccess({
          content: 'Delete Success',
        })
        loadData()
      })
      .catch(useNotifyErrors)

  watch([options], () => {
    loadData()
  })

  watch(filters, debounce(loadData, 300))

  return {
    loading,

    tableColumns,
    monthlySummaryTableColumns,
    tableItems,
    tableTotal,

    options,
    filters,

    loadData,
    deleteEmailTemplate,
  }
}
