<template>
  <v-form ref="formElem">
    <v-card>
      <translation-locales />

      <v-card-text>
        <v-autocomplete
          v-model="form.distributor_id"
          :disabled="!!resource"
          :items="distributorList"
          item-text="business_name"
          item-value="id"
          label="*Select Distributor"
          outlined
          hide-details
          :rules="[required]"
        ></v-autocomplete>

        <v-text-field
          v-model="form.nicename"
          label="Display Name"
          :rules="[required]"
        />

        <translatable-input
          v-model="form.subject"
          class="mt-5"
          label="Email Subject"
        />

        <translatable-textarea
          v-model="form.content"
          class="mt-5"
          label="Email Content"
        />

        <p>This supports custom formatting:</p>
        <ul>
          <li>[[first_name,last_name]] (First name, last name)</li>
          <li>[[total_remain_reward_points]] Total remain healthcoin</li>
          <li>[[distributor_slug_portal_url]] Distributor slug portal URL</li>
          <li>[[reward_point_expiry_datetime|date,format:Y-m-d]]</li>
        </ul>
        <br>
      </v-card-text>

      <v-card-actions class="justify-center">
        <v-btn
          color="primary"
          :loading="loading"
          @click="validate"
        >
          Submit
        </v-btn>
      </v-card-actions>

      <v-alert
        v-for="msg in errors"
        :key="msg"
        color="warning"
      >
        {{ msg }}
      </v-alert>
    </v-card>
  </v-form>
</template>

<script>
import { TranslatableInput, TranslatableTextarea, TranslationLocales } from '@/components'
import { useTranslatable } from '@/composables'
import { required } from '@core/utils/validation'
import { ref } from '@vue/composition-api'
import useSites from '../../useSites'

export default {
  components: { TranslatableTextarea, TranslatableInput, TranslationLocales },

  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    disabledFields: {
      type: Array,
      default() {
        return []
      },
    },
    errors: {
      type: Array,
      default() {
        return []
      },
    },
    resource: {
      // default resource
      type: [Object, Boolean],
      default: false,
    },
    templateType: {
      type: String,
      default: 'reward_point_expiry_reminder',
    },
  },

  setup(props, { emit }) {
    const { loadDistributors, distributorList } = useSites()
    loadDistributors()

    // Get value from resource prop, use initValue if not exist
    const field = (name, initValue) => ({
      [name]: props.resource ? props.resource[name] : initValue,
    })

    const initialForm = {
      ...field('nicename', ''),
      ...field('type', 'reward_point_expiry_reminder'),
      ...field('subject', useTranslatable('subject')),
      ...field('content', useTranslatable('content')),
      distributor_id: props.resource ? props.resource.distributor.id : null,
    }

    const form = ref({ ...initialForm })
    const formElem = ref(null)

    const validate = () => {
      if (formElem.value.validate()) emit('submit', form.value)
    }

    return {
      form,
      formElem,
      validate,
      required,

      distributorList,
    }
  },
}
</script>
